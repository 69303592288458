// ============== imports ================
import './index.css'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
// ============ END imports ================

// ============== constant ================
const API_BASE_URL = localStorage.getItem('API_BASE_URL') || '/api';
const UPLOAD_LINK = API_BASE_URL + '/upload-zip';
const HEADERS = {'Access-Control-Allow-Origin':'*'}
// ============ END constant ================

// ============== login ================
document.getElementById("submitBtn").onclick = function() {doLogin()}; //add event to button

/**
 * check the login inputs. Currently not safe
 */
function doLogin(){
    var username = document.getElementById("username").value;
    var password = document.getElementById("password").value;
    if(username=="client"){
        document.getElementById("form-content").setAttribute("style", "display: none;");
        document.getElementById("client").setAttribute("style", "display: block;");
    }else if(username=="archi"){
        document.getElementById("form-content").setAttribute("style", "display: none;");
        document.getElementById("archi").setAttribute("style", "display: block;");
    }else{
        alert("wrong username / password. Try \"client\" or \"archi\"");
    }
}
// ============ END login ================

// ============== upload ================
// detect changes on the file picker --> perform upload
const fileSelector = document.getElementById('file-upload');
fileSelector.addEventListener('change', (event) => {
    const fileList = event.target.files; // the files picked
    // set data to send to API
    const formData = new FormData();
    formData.append('models', fileList[0]);
    // send to server
    fetch(UPLOAD_LINK, {
        method: "POST",
        mode: 'cors',
        headers: HEADERS,
        body: formData,
    }).then(async (response) => {
        // request is successful
        const data = await response.text(); // transform API's response to text
        console.log(data);
        // show toast to user as success
        const x = document.getElementById("snackbar");
        x.className = "show";
        setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
    });
});
// ============ END upload ================
